import Vue from "vue";
import VueI18n from "vue-i18n";
import ja from "@/locale/ja.json";
import en from "@/locale/en.json";

import zh from "@/locale/zh.json";
import ko from "@/locale/ko.json";
import store from '@/store'

Vue.use(VueI18n);

const messages = {
  ja: ja,
  en: en,
  zh: zh,
  ko: ko,
};

const i18n = new VueI18n({
  locale: store.state.language,
  fallbackLocale: store.state.language,
  messages,
});

export default i18n;